@import url('https://fonts.googleapis.com/css2?family=MonteCarlo&family=Roboto+Condensed:wght@400&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 400px;
  pointer-events: none;
}

.App-username {
  font-family: 'MonteCarlo', cursive;
  margin-bottom: 0;
}

.App-about {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  text-align: justify;
  margin-top: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-social {
  display: flex;
}

.App-social > a {
  margin-right: 12px;
  cursor: pointer;
  color: white;
}

.App-social > a:hover {
  opacity: 0.8;
}

.App-link {
  color: #61dafb;
}
